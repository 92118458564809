import React from "react";
import HomePage from "./Components/HomePage"
import './App.css';

function App() {
  return (
    <HomePage>
    </HomePage>
  );
}

export default App;
